/* You can add global styles to this file, and also import other style files */

html, body
{
  height: 100%;
}

body
{
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  &.loading
  {
    pointer-events: none;
    overflow: hidden;
  }
}

.spacer
{
  flex: 1 1 auto;
}

.full-width
{
  width: 100%;
}

tbody
{
  tr
  {
    &:nth-child(odd)
    {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  td.mat-cell
  {
    padding-left: 10px;
    padding-right: 10px;

    &.mat-column-actions
    {
      border-left-style: solid;
      border-left-width: 1px;
    }
  }
}

.table-container
{
  .table-filters
  {
    display: flex;
    justify-content: space-between;
  }

  .table-wrapper
  {
    .mat-table
    {
      width: 100%;

      img
      {
        height: 50px;
      }
    }
  }
}

.mat-list-item
{
  b
  {
    padding-left: 5px;
  }
}
